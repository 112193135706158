{
  "name": "drillingllc",
  "version": "1.69.1",
  "scripts": {
    "ng": "ng",
    "serve:jtms-front-end": "ng serve --project jtms-front-end",
    "serve:pressure-test": "ng serve --project pressure-test",
    "serve:torque-ui": "ng serve --project torque-ui",
    "1:build:dis-gapi:watch": "ng build @dis/gapi --watch",
    "2:build:dis-auth:watch": "ng build @dis/auth --watch",
    "3:build:dis-models:watch": "ng build @dis/models --watch",
    "4:build:dis-modules:watch": "ng build @dis/modules --watch",
    "5:build:dis-shared:watch": "ng build @dis/shared --watch",
    "6:build:dis-webrelay:watch": "ng build @dis/webrelay --watch",
    "build:dis:libraries": "ng build @dis/gapi && ng build @dis/auth && ng build @dis/models && ng build @dis/modules && ng build @dis/shared && ng build @dis/webrelay",
    "build:jtms:production": "ng build --project jtms-front-end --configuration production",
    "build:jtms:staging": "ng build --project jtms-front-end --configuration staging",
    "build:pressure-test:production": "ng build --project pressure-test --configuration production",
    "build:pressure-test:staging": "ng build --project pressure-test --configuration staging",
    "build:production": "npm run build:dis:libraries && npm run build:jtms:production",
    "build:staging": "npm run build:dis:libraries && npm run build:jtms:staging",
    "firebase:emulators:start": "firebase emulators:start --import .emulators/data --export-on-exit",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "deploy:jtms:production": "firebase deploy --only hosting:jtms-production",
    "deploy:jtms:staging": "firebase deploy --only hosting:jtms-staging",
    "deploy:pressure-test:production": "firebase deploy --only hosting:pressure-test",
    "sentry:jtms-front-end:sourcemaps": "sentry-cli sourcemaps inject --org drilling-innovative-systems --project jtms-front-end dist/jtms-front-end && sentry-cli sourcemaps upload --org drilling-innovative-systems --project jtms-front-end dist/jtms-front-end",
    "sentry:pressure-test:sourcemaps": "sentry-cli sourcemaps inject --org drilling-innovative-systems --project pressure-test dist/pressure-test && sentry-cli sourcemaps upload --org drilling-innovative-systems --project pressure-test dist/pressure-test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "18.2.6",
    "@angular/cdk": "^18.2.6",
    "@angular/common": "18.2.6",
    "@angular/compiler": "18.2.6",
    "@angular/core": "18.2.6",
    "@angular/fire": "^18.0.1",
    "@angular/forms": "18.2.6",
    "@angular/material": "^18.2.6",
    "@angular/platform-browser": "18.2.6",
    "@angular/platform-browser-dynamic": "18.2.6",
    "@angular/router": "18.2.6",
    "@angular/service-worker": "18.2.6",
    "@ngxs-labs/firestore-plugin": "^18.0.2",
    "@ngxs/form-plugin": "^18.1.1",
    "@ngxs/router-plugin": "^18.1.1",
    "@ngxs/storage-plugin": "^18.1.1",
    "@ngxs/store": "^18.1.1",
    "@ngxs/websocket-plugin": "^18.1.1",
    "@rx-angular/state": "^18.0.0",
    "@rx-angular/template": "^18.0.2",
    "@sentry/angular": "^8.32.0",
    "@sentry/cli": "^2.36.4",
    "@zxing/browser": "^0.1.5",
    "@zxing/library": "^0.21.3",
    "@zxing/ngx-scanner": "^18.0.1",
    "angular-google-charts": "^16.0.1",
    "firebase": "^10.14.0",
    "jsqr": "^1.4.0",
    "lodash": "^4.17.21",
    "mapbox-gl": "^3.7.0",
    "ngx-doc-viewer": "^15.0.1",
    "ngx-print": "^1.5.1",
    "ngx-sub-form": "^11.0.0",
    "observable-webworker": "^6.0.1",
    "rxjs": "^7.8.1",
    "tslib": "^2.7.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.6",
    "@angular/cli": "~18.2.6",
    "@angular/compiler-cli": "^18.2.6",
    "@commitlint/cli": "^19.5.0",
    "@commitlint/config-conventional": "^19.5.0",
    "@ngxs/devtools-plugin": "^18.1.1",
    "@types/gapi": "0.0.47",
    "@types/gapi.client.chat-v1": "^0.0.4",
    "@types/gapi.client.discovery-v1": "^0.0.4",
    "@types/gapi.client.drive-v3": "^0.0.5",
    "@types/gapi.client.script-v1": "^0.0.4",
    "@types/gapi.client.sheets-v4": "^0.0.4",
    "@types/jasmine": "~5.1.4",
    "@types/mapbox-gl": "^3.4.0",
    "@types/node": "^22.7.4",
    "concurrently": "^8.2.2",
    "jasmine-core": "~5.3.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "ng-packagr": "^18.2.1",
    "typescript": "~5.4.5"
  },
  "overrides": {
    "angular-google-charts": {
      "@angular/common": "^18",
      "@angular/core": "^18"
    }
  }
}
