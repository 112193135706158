import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@dis/auth';

import { PageNotFoundComponent } from './ui/page-not-found/page-not-found.component';
import { HomeComponent } from './ui/home/home.component';
import { AboutComponent } from './ui/about/about.component';
import { TestingOnlyComponent } from './testing/testing-only/testing-only.component';
import { SettingsComponent } from './components/settings/settings.component';
import { FcmComponent } from './standalone/firebase-cloud-messaging/fcm';

const routes: Routes = [
  {
    path: 'about',
    component: AboutComponent
  },

  {
    path: 'home',
    component: HomeComponent
  },

  {
    path: 'fcm',
    component: FcmComponent,
  },

  {
    path: 'dashboard/jobs-by-status',
    loadChildren: () => import('projects/jtms-front-end/src/app/dashboards/jobs-by-status/jobs-by-status.module').then(m => m.JobsByStatusModule),
  },

  {
    path: 'dashboard/jobs-gantt',
    loadChildren: () => import('projects/jtms-front-end/src/app/dashboards/jobs-gantt-charts/jobs-gantt-charts.module').then(m => m.JobsGanttChartsModule),
  },

  {
    path: 'dashboard/meeting-mode',
    loadChildren: () => import('projects/jtms-front-end/src/app/dashboards/meeting-mode/meeting-mode.module').then(m => m.MeetingModeModule),
  },

  {
    path: 'dashboard/tools-bar',
    loadChildren: () => import('projects/jtms-front-end/src/app/dashboards/tools-bar-charts/tools-bar-charts.module').then(m => m.ToolsBarChartsModule),
  },

  {
    path: 'quotes',
    canActivate: [AuthGuard],
    loadChildren: () => import('projects/jtms-front-end/src/app/jtms/0-quotes/0-quotes.module').then(m => m.QuotesModule)
  },

  {
    path: 'inventory',
    canActivate: [AuthGuard],
    loadChildren: () => import('projects/jtms-front-end/src/app/inventory-manager/inventory-manager.module').then(m => m.InventoryManagerModule)
  },

  {
    path: 'job-tool-management-dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('projects/jtms-front-end/src/app/dashboards/job-tool-management-dashboard/job-tool-management.module').then(m => m.JobToolManagementModule)
  },

  {
    path: 'projects',
    canActivate: [AuthGuard],
    loadChildren: () => import('projects/jtms-front-end/src/app/jtms/1_projects/projects.module').then(m => m.ProjectsModule)
  },

  {
    path: 'jobs',
    canActivate: [AuthGuard],
    loadChildren: () => import('projects/jtms-front-end/src/app/jtms/2_jobs/jobs.module').then(m => m.JobsModule)
  },

  {
    path: 'builds',
    canActivate: [AuthGuard],
    loadChildren: () => import('projects/jtms-front-end/src/app/jtms/3_builds/builds.module').then(m => m.BuildsModule)
  },

  {
    path: 'assembly',
    canActivate: [AuthGuard],
    loadChildren: () => import('projects/jtms-front-end/src/app/jtms/4_assembly/assembly.module').then(m => m.AssemblyModule)
  },

  {
    path: 'components',
    canActivate: [AuthGuard],
    loadChildren: () => import('projects/jtms-front-end/src/app/jtms/5_components/components.module').then(m => m.ComponentsModule)
  },

  {
    path: 'containers',
    canActivate: [AuthGuard],
    loadChildren: () => import('projects/jtms-front-end/src/app/containers/containers.module').then(m => m.ContainersModule)
  },

  {
    path: 'parts',
    canActivate: [AuthGuard],
    loadChildren: () => import('projects/jtms-front-end/src/app/jtms/6_parts/parts.module').then(m => m.PartsModule)
  },

  {
    path: 'office',
    canActivate: [AuthGuard],
    loadChildren: () => import('projects/jtms-front-end/src/app/office/office.module').then(m => m.OfficeModule)
  },

  {
    path: 'shop',
    canActivate: [AuthGuard],
    loadChildren: () => import('projects/jtms-front-end/src/app/shop/shop.module').then(m => m.ShopModule)
  },

  {
    path: 'settings',
    component: SettingsComponent,
  },

  {
    path: 'test',
    component: TestingOnlyComponent
  },

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },

  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 128] // [x, y]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
